import { useState } from "preact/hooks"
import { useMutation } from "urql"

const AUTH_TOKEN = "token"

let getToken = () => {}
let setToken = token => {}
let deleteToken = () => {}

if (typeof window !== 'undefined') {
    getToken = () => localStorage.getItem(AUTH_TOKEN)
    setToken = token => localStorage.setItem(AUTH_TOKEN, token)
    deleteToken = () => localStorage.removeItem(AUTH_TOKEN)
}

export { getToken, setToken, deleteToken }