import { Router, route } from 'preact-router'
import Home from '../routes/home'
import Customer from '../routes/customer'
import Booking from '../routes/booking'

import { createClient, Provider } from 'urql'
import Login from '../routes/login'
import { getToken } from './../services/token'
import Profile from '../routes/profile'

const App = () => {
  const handleRoute = async (e) => {
    switch (e.url) {
      case '/':
      case '/booking':
      case '/calendar':
      case '/customer':
      case '/profile':
        const isLoggedIn = !!getToken()
        if (!isLoggedIn) route('/login', true)
        break
    }
  }

  const urqlClient = createClient({
    // url: 'http://localhost:8000/',
    url: 'https://api.lacascade.qlikdata.io/',
    fetchOptions: () => {
      return {
        headers: {
          authorization: getToken() ? `Bearer ${getToken()}` : '',
        },
      }
    },
  })

  return (
    <div id="app">
      <Provider value={urqlClient}>
        <Router onChange={handleRoute}>
          <Home path="/" />
          <Booking path="/bookings" default />
          <Customer path="/customer" />
          <Login path="/login" />
          <Profile path="/profile" />
        </Router>
      </Provider>
    </div>
  )
}

export default App
